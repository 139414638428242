export default {
  methods: {
    openMastercard (config) {
      window.Checkout.configure({
        session: {
          id: config.data.session.id
        },
        interaction: {
          cancelUrl: 'https://www.google.com',
          merchant: {
            name: config.data.merchant,
            address: {
              line1: '200 Sample St',
              line2: '1234 Example Town'
            }
          }
        },
        order: config.data.order
      })
      window.Checkout.showEmbeddedPage('#embed-target1')
      // window.Checkout.showPaymentPage()
    }
  }
}
