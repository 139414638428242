<template>
  <b-button :variant="variant || 'primary'" :style="styles" class="btn-with-icon" :class="{'btn-with-icon-only': !title}" @click="$emit('clickFn')">
    <span class="icon d-flex align-items-center justify-content-center" :style="{color: color}">
      <i :class="icon" class="m-0"></i>
    </span>
    <span class="text" v-if="title">{{title}}</span>
  </b-button>
</template>

<script>
export default {
  props: ['icon', 'title', 'variant', 'styles', 'color']
}
</script>
