<template>
<div :class="classes" class="user-wallet iq-card text-center d-flex align-items-center justify-content-center">
  <div class="iq-card-body w-100">
    <h2 class="balance">{{data.balance}}<sub class="cuurency">ر.س</sub></h2>
    <h3 class="balance-title">{{data.title}}</h3>
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  props: ['data', 'classes']
}
</script>
